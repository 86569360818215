@import "custom";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  // background-color: #f1f2f3;
}

.container {
  max-width: 1236px;
}

a {
  text-decoration: none;
}

.content {
  position: relative;
  top: 83px;
}

.img-lazy {
  background-color: rgb(228, 228, 228);
}

/* Make clicks pass-through */

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
}

/* Fancy blur effect */

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */

#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  border: solid 3px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.carousel-placeholder {
  min-height: 270px;
}

.whatsapp {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 1rem 0.5rem;
  z-index: 1031;
  width: 125px;
  .scaffold {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .wa-btn {
      position: absolute;
      top: -60px;
      right: 0px;
    }
  }
  &:before {
    content: url("/assets/waving.svg");
    display: inline-block;
    width: 125px;
    position: relative;
    bottom: -50px;
    right: 15px;
  }
}

.login-bg-img {
  background-image: url("/assets/bg_biru_1.jpg");
  background-size: cover;
  background-position: top;
}

.login-bg-img-new {
  background-image: url("/assets/bg_biru_2.png");
  background-size: cover;
  background-position: top;
}

.header-bg-img {
  background-image: url("/assets/bg_header.png");
  background-size: cover;
  background-position: top;
}

@media (max-width: 767px) {
  .header-bg-img {
    background-image: url("/assets/bg_responsive.png");
    background-position: center;
    max-height: 800px;
    border-bottom-right-radius: 0.1rem;
    border-bottom-left-radius: 4rem;
  }
}

.btn {
  padding-left: 20px;
  padding-right: 20px;
  padding-block: 14px;
}

.card {
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.card-img {
  border-radius: 15px 15px 0 0;
}

.carousel-indicators li {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  bottom: -10px;
}

.carousel-indicators li.active {
  background-color: #006da7;
}

.header-listing-bg-img {
  background-image: url("/assets/header_listing.png");
  background-size: cover;
  background-position: top;
}

.header-add-listing-bg-img {
  background-image: url("/assets/bg_add_listing.png");
  background-size: cover;
  background-position: top;
}

.login-left-bg-img {
  background-image: url("/assets/login_bg.png");
  background-size: cover;
  background-position: top;
  border-radius: 15px;
}

.login-right-bg-img {
  background-image: url("/assets/login_right_bg.png");
  background-size: cover;
  background-position: top;
  border-radius: 0 15px 15px 0;
}

.sticky-top {
  top: 100px;
}

.text-secondary-mobile {
  color: grey;
}

@media (max-width: 767px) {
  .text-secondary-mobile {
    font-size: 32px;
  }
  .text-add-listing {
    font-size: 20px;
  }
}

.listing-detail-main-image {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  width: 100%;
  height: 510px;
}

.listing-detail-image-top {
  border-top-right-radius: 20px;
  width: 100%;
  height: 250px;
}
.listing-detail-image-bottom {
  border-bottom-right-radius: 20px;
  width: 100%;
  height: 250px;
}

.listing-detail-project-main-image {
  border-radius: 25px;
  width: 100%;
  height: 530px;
}

.listing-detail-project-image {
  border-radius: 25px;
  width: 100%;
  height: 171px;
}

.recommended-item:hover {
  background-color: grey;
  color: white;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.line-clamp.expand {
  -webkit-line-clamp: initial;
}

.show-more {
  position: absolute;
  bottom: 0;
  right: 0;
}

.forgot-password-form {
  width: 500px;
}

.background-checkout {
  background-color: #006da720;
}

.package-card-header {
  background-color: #efefef;
  border-radius: 8px 8px 0px 0px;
}

.package-card-header-primary {
  background-color: #0089ce;
  border-radius: 8px 8px 0px 0px;
}

.translate-x {
  transform: translateY(25px);
}

.package-card {
  position: relative;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.small-secondary-text {
  font-size: 15px;
  font-weight: 600;
  padding-inline: 12px;
  padding-block: 5px;
}

.package-card-heading {
  color: #fff;
  border-radius: 9999px;
  position: absolute;
  bottom: -28px; /* Adjust this value to control how much the capsule extends outside */
  left: 50%;
  transform: translateX(-50%);
}

/* capsule toggle */
.custom-capsule-switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 40px;
  border-color: #006da7;
}

.custom-capsule-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #007bff20;
  border-radius: 20px; /* Capsule shape */
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 28px;
  width: 28px;
  left: 7px;
  bottom: 7px;
  background-color: #007bff;
  border-radius: 50%; /* Circular handle */
  transition: 0.4s;
}

input:checked + .slider:before {
  transform: translateX(38px);
}
/* capsule toggle end */

.agent-banner-bg-img {
  background-image: url("/assets/agent-banner-bg.png");
  background-size: cover;
  background-position: top;
}

.promo-banner-bg-img {
  background-image: url("/assets/kemerdeka.svg");
  background-size: cover;
  height: 500px;
}

.paket-bg-img {
  background-image: url("/assets/aurora-purple.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.footer-bg-img {
  background-image: url("/assets/banner-footer.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}

.down-app-bg-img {
  background-image: url("/assets/banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}

.down-app-mobile-bg-img {
  background-image: url("/assets/bg-down-app-mobile.png");
  background-size: cover;
  background-position: top;
}

.btn[disabled] {
  background-color: #c9c9c9;
  color: #fff;
  border: none;
  opacity: 1;
}

.card-banner-slider {
  height: 17.5rem;
}

@media (max-width: 480px) {
  .whatsapp {
    position: fixed;
    bottom: 90px;
    right: 0;
  }
  #nprogress .bar {
    top: 0;
  }
  #nprogress .spinner {
    top: 5px;
    right: 5px;
  }
  .carousel-placeholder {
    min-height: calc(100vw / 1.78);
  }
  .header-bg-img {
    background-position: right;
  }
  .header-add-listing-bg-img {
    background-position: right;
  }
  .text-secondary-mobile {
    color: white;
  }
  .header-listing-bg-img {
    background-size: cover;
    background-position: left;
  }
  .forgot-password-form {
    width: 100%;
    padding: 20px;
  }
  .card-banner-slider {
    height: 250px;
  }
}

.height-res {
  display: flex;
  align-items: center;
  height: 100vh;
}

@media (max-width: 767px) {
  .height-res {
    height: 40vh;
    margin-bottom: 10px;
  }
  .body-card {
    margin: 30%;
  }
  .add-listing-bg {
    padding: 2%;
  }
}

.footer-logo {
  color: #4a596b;
}

.project-baru-bg-img {
  background-image: url("/assets/project-baru-bg.png");
  background-color: "#006EA7CC";
  background-size: cover;
  background-position: top;
  width: 100%;
  min-height: 362px;
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 71.181%,
    100% 71.181%,
    99.132% 71.988%,
    96.69% 74.179%,
    92.922% 77.406%,
    88.071% 81.326%,
    82.384% 85.591%,
    76.106% 89.856%,
    69.484% 93.775%,
    62.761% 97.003%,
    56.185% 99.193%,
    50% 100%,
    50% 100%,
    43.815% 99.193%,
    37.239% 97.003%,
    30.516% 93.775%,
    23.893% 89.856%,
    17.616% 85.591%,
    11.929% 81.326%,
    7.078% 77.406%,
    3.31% 74.179%,
    0.868% 71.988%,
    0% 71.181%,
    0% 0%
  );
}

.text-project-baru {
  font-size: 35px;
  color: white;
  text-align: center;
}

.produk-baru-select {
  height: 46px;
  width: 28.9%;
  border-radius: 10px;
}

.font-roboto {
  font-family: "Roboto Serif", sans-serif;
}

.slick-list {
  // padding: 0 20% 0 0 !important;
}
.slick-slide > div {
  padding: 0 5px;
}

input::placeholder {
  color: #c4c4c4;
}

.register-input {
  border: 1px solid #c4c4c4;
  padding: 10px;
  border-radius: 10px;
  transition: border-color 0.3s ease;
}

.register-input:hover {
  border-color: #006ea7;
}

.register-label-1-word {
  position: absolute;
  background-color: white;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  font-size: 15px;
  font-weight: 500;
  color: #606060;
  transform: translateY(-50%) translateX(20%);
  pointer-events: none;
}

.register-label-2-word {
  position: absolute;
  background-color: white;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  font-size: 15px;
  font-weight: 500;
  color: #606060;
  transform: translateY(-50%) translateX(10%);
  pointer-events: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with 50% opacity */
  z-index: 50; /* Ensure it's above other content */
}

.custom-placeholder::placeholder {
  font-style: italic;
}

.custom-modal {
  width: 700px;
  max-width: 100%;
  margin: auto;
  border-radius: 1.25em;
}

.btn-price-primary {
  background-color: #008cff;
  color: white;
}

.btn-price-primary:hover {
  background-color: #0072cf;
  color: white;
}

.btn-price-outline-primary {
  border: 1px solid #008cff;
  color: #1c92d2;
}

.btn-price-outline-primary:hover {
  background-color: #008cff;
  border: 1px solid #008cff;
  color: white;
}

.package-card-background {
  background-color: transparent; /* Or any other default style */
}
@media (min-width: 768px) {
  .package-card-background {
    clip-path: polygon(
      0% 10.331%,
      0% 9.331%,
      0% 8.264%,
      0% 7.594%,
      0.2% 6.958%,
      0.4% 6.365%,
      0.682% 5.824%,
      1% 5.343%,
      1.35% 4.93%,
      1.754% 4.593%,
      2.197% 4.343%,
      2.672% 4.186%,
      3.173% 4.132%,
      25.763% 4.132%,
      25.763% 4.132%,
      26.001% 4.145%,
      26.237% 4.181%,
      26.468% 4.242%,
      26.695% 4.325%,
      26.916% 4.431%,
      27.131% 4.56%,
      27.337% 4.71%,
      27.536% 4.882%,
      27.724% 5.074%,
      27.902% 5.286%,
      30.122% 8.144%,
      30.122% 8.144%,
      30.3% 8.356%,
      30.489% 8.548%,
      30.687% 8.72%,
      30.894% 8.87%,
      31.109% 8.998%,
      31.33% 9.105%,
      31.556% 9.188%,
      31.788% 9.249%,
      32.023% 9.285%,
      32.262% 9.298%,
      96.827% 9.298%,
      96.827% 9.298%,
      97.328% 9.352%,
      97.803% 9.508%,
      98.246% 9.759%,
      98.65% 10.095%,
      99.01% 10.508%,
      99.318% 10.989%,
      99.569% 11.531%,
      99.756% 12.124%,
      99.873% 12.759%,
      99.914% 13.43%,
      100% 15%,
      100% 30%,
      100% 50%,
      100% 70%,
      100% 85%,
      100% 90%,
      100% 100%,
      0% 100%
    );
    padding-inline: 0px;
    padding-top: 35px;
    border-radius: 20px;
  }
}

.landing-height {
  height: 803px;
  position: relative;
}

@media (max-width: 767px) {
  .landing-height {
    height: 500px;
    padding-bottom: 100px;
  }
}

.custom-search-rounded {
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  border-top-right-radius: 0;
}

@media (min-width: 768px) {
  .custom-search-rounded {
    border-top-right-radius: 1.5rem;
  }
}

.menu-color {
  clip-path: polygon(0 0, 100% 0, 85% 100%, 15% 100%);
  display: inline-block;
  width: 6.8em;
  height: 3em;
}

.bg-menu {
  clip-path: polygon(
    3.534% 26.349%,
    3.534% 26.349%,
    3.785% 21.543%,
    4.057% 17.18%,
    4.346% 13.275%,
    4.651% 9.842%,
    4.971% 6.896%,
    5.303% 4.453%,
    5.646% 2.527%,
    5.998% 1.133%,
    6.357% 0.286%,
    6.721% 0%,
    100% 0%,
    100% 100%,
    0% 100%,
    3.534% 26.349%
  );
}

.input-search-project-baru {
  border: 0;
  width: 100%;
  height: 100%;
}

.input-search-project-baru:focus {
  border: 0;
}

.bg-gradient-dark {
  background: linear-gradient(
    to bottom,
    #00000000,
    #00000099
  ); /* Adjust colors as needed */
}

.news-post-date {
  display: flex;
  align-items: center;
}

.triangle-blue-svg {
  clip-path: polygon( 0% 0%,100% 100%,0% 100%,0% 0% );
  background-color: #006da7;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 0;
}

.two-line-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-menu-gap {
  gap: 20px;
}

@media (max-width: 1549px) {
  .header-menu-gap {
    gap: 10px;
  }
}

@media (max-width: 1225px) {
  .header-menu-gap {
    gap: 5px;
  }
}

@media (max-width: 1150px) {
  .header-menu-gap {
    gap: 0px;
  }
}
